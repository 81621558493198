<template>
  <div class="home-six">
    <div class="title">
      <span class="line"></span>
      <span class="text">{{ langvalue.top_1.three }}</span>
      <span class="line"></span>
    </div>
    <div class="detail">
      <div class="main">
        <Swiper
          :slidesPerView="1"
          :spaceBetween="30"
          :loop="false"
          :centeredSlides="false"
          :pagination="{ clickable: true}"
          :autoplay="{ delay: 900, disableOnInteraction: false }"
          :navigation="false"
          :modules="modules"
          @slideChange="onSlideChange"
        >
          <swiper-slide>
            <div class="main_title">> {{ langvalue.section4_3_2.one }}</div>
            <div class="img">
              <img src="https://resources.wukongbc.com/img/kuangchang/2.png" />
            </div>
            <div class="right">
              <div class="right_main">
                <div class="r_title">{{ langvalue.section4_3_2.two }}</div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_2.three.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_2.three.split('：')[1] }}
                </div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_2.four.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_2.four.split('：')[1] }}
                </div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_2.five.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_2.five.split('：')[1] }}
                </div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_2.six.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_2.six.split('：')[1] }}
                </div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_2.seven.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_2.seven.split('：')[1] }}
                </div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_2.eight.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_2.eight.split('：')[1] }}
                </div>

                <div class="play">
                  <div class="goplay" @click="jiankongvideo">
                    <img src="../../../assets/images/play.png" />
                    <div>{{ langvalue.other.one1 }}</div>
                  </div>
                  <div class="goplay" @click="govideopage">
                    <img
                      src="https://resources.wukongbc.com/icon-pc/play.png"
                    />
                    <div>{{ langvalue.other.one }}</div>
                  </div>
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="main_title">> {{ langvalue.section4_3_1.one }}</div>
            <div class="img">
              <img src="https://resources.wukongbc.com/img/kuangchang/4.png" />
            </div>
            <div class="right">
              <div class="right_main">
                <div class="r_title">{{ langvalue.section4_3_1.two }}</div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_1.three.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_1.three.split('：')[1] }}
                </div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_1.four.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_1.four.split('：')[1] }}
                </div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_1.five.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_1.five.split('：')[1] }}
                </div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_1.six.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_1.six.split('：')[1] }}
                </div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_1.seven.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_1.seven.split('：')[1] }}
                </div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_1.eight.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_1.eight.split('：')[1] }}
                </div>
              </div>
            </div>
          </swiper-slide>
          <swiper-slide>
            <div class="main_title">> {{ langvalue.section4_3_3.one }}</div>
            <div class="img">
              <img src="https://resources.wukongbc.com/img/kuangchang/6.png" />
            </div>
            <div class="right">
              <div class="right_main">
                <div class="r_title">{{ langvalue.section4_3_3.two }}</div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_3.three.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_3.three.split('：')[1] }}
                </div>
                 <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_3.four.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_3.four.split('：')[1] }}
                </div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_3.five.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_3.five.split('：')[1] }}
                </div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_3.six.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_3.six.split('：')[1] }}
                </div>
                <div class="mainitem">
                  <img class="ricon" src="../../../assets/images/t.png" />
                  <span class="label">
                    {{ langvalue.section4_3_3.seven.split('：')[0] }}：</span
                  >
                  {{ langvalue.section4_3_3.seven.split('：')[1] }}
                </div>

              </div>
            </div>
          </swiper-slide>
        </Swiper>
        <div class="indicator">
          <div :class="['item', activeIndex == 0 ? 'active' : '']"></div>
          <div :class="['item', activeIndex == 1 ? 'active' : '']"></div>
          <div :class="['item', activeIndex == 2 ? 'active' : '']"></div>
        </div>

        <!-- <div class="more_box" v-if="moreShow">
          <div class="more" @click="goAboutInfo">
            <span>{{ langvalue.other.six }}</span>
            <img src="../../../assets/images/arrow-right.png" alt="" />
          </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, watch } from 'vue'
import { useStore } from 'vuex'
import { returnLanguage } from '../../../assets/js/language'
import router from '@/router'
import { Swiper, SwiperSlide } from 'swiper/vue' // swiper 所需组件
import { Autoplay, Navigation, Pagination, A11y } from 'swiper'// 分页器
// import { Autoplay, Navigation, Pagination, A11y } from 'swiper'
import 'swiper/swiper-bundle.css'
// export default {
//   name: 'AppHeaderNav',
//   props: ['showMore'],
//   setup(props) {
const props = defineProps({
  showMore: {
    type: Boolean
  }
})
let moreShow = ref(props.showMore)

const modules = [Autoplay, Pagination, Navigation, A11y]
const store = useStore()
// 拿到vuex中的分类列表
const langtype = store.state.home.type
// eslint-disable-next-line no-undef
const langvalue = ref(returnLanguage(langtype))
const checkedtype = ref(langvalue.value.section4_3_3.one)
console.log('', langvalue.value.section4_3_1.one)
const type = ref(langtype)
const checktype = type => {
  checkedtype.value = type
}
watch(
  () => store.state.home.type,
  newVal => {
    langvalue.value = returnLanguage(newVal)
    checkedtype.value = returnLanguage(newVal).section4_3_2.one
    type.value = newVal
  },
  { immediate: true }
)
let activeIndex = ref(0)
const govideopage = () => {
  window.localStorage.setItem('mao', '#m6')
  router.push('/playvideo')
}
const jiankongvideo = () => {
  window.localStorage.setItem('mao', '#m6')
  router.push('/jiankongvideo')
}
const goAboutInfo = () => {
  window.localStorage.setItem('mao', '#m6')
  router.push('/trustcenter')
}

const onSlideChange = (e) => {
  activeIndex.value = e.activeIndex
  console.log('e', e.activeIndex)
}
const onSwiper = (index) => {
  console.log(index);
}

// 鼠标悬停 .goplay 则修改为 color: #578aef  src:https://resources.wukongbc.com/icon-pc/play2.png
// 鼠标离开 .goplay 则修改为 color: #000000  src:https://resources.wukongbc.com/icon-pc/play.png
// const goplay = () => {
// 鼠标悬停 .goplay

// if (img.src === 'https://resources.wukongbc.com/icon-pc/play.png') {
//   img.src = 'https://resources.wukongbc.com/icon-pc/play2.png'
//   div.style.color = '#578aef'
// } else {
//   img.src = 'https://resources.wukongbc.com/icon-pc/play.png'
//   div.style.color = '#000000'
// }

// }

//     return {
//       langvalue,
//       checkedtype,
//       checktype,
//       type,
//       jiankongvideo,
//       govideopage,
//       goAboutInfo,
//       moreShow
//     }
//   }
// }
</script>
<style scoped lang="less">
.home-six {
  padding: 126px 0 77px;
  // width: 1240px;
  margin: 0 auto;
  background: #f9f9f9;
}

.title {
  font-size: 40px;
  // font-weight: bold;
  position: relative;
  // border-bottom: 3px solid;
  // border-image: linear-gradient(to left, rgba(217, 255, 253, 1), #578aef) 1;
  // padding-bottom: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  .line {
    display: inline-block;
    width: 100px;
    height: 2px;
    background: #d3d5e0;
  }

  .text {
    margin: 0 10px;
  }
}

.detail {
  width: 1340px;
  font-size: 20px;
  font-weight: 300;
  margin: 148px auto 0;
  display: flex;
  // flex-wrap: wrap;
  // border-radius: 32px;

  .main {
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 2px auto;
    // background: white;
    border-radius: 32px;
    position: relative;


    .indicator {
      margin-top: 60px;
      display: flex;
      justify-content: center;

      .item {
        width: 18px;
        height: 18px;
        background: #cbcbcb;
        border-radius: 50%;

        &.active {
          background: #000;
        }
      }

      .item+.item {
        margin-left: 20px;
      }
    }

    .main_title {
      font-size: 35px;
      color: #586477;
      width: 600px;
      text-wrap: wrap;
    }

    .img {
      margin-top: 86px;

      width: 1008px;
      height: 594px;

      img {
        border-radius: 15px;
      }
    }

    .right {
      position: absolute;
      background: #fff;
      top: -36px;
      right: 0;
      // width: 779px;
      // height: 658px;
      border-radius: 24px 0 0 24px;
      box-shadow: 0px 30px 80px 0px rgba(0, 0, 0, 0.04);

      .right_main {
        padding: 79px 49px 46px;

        .r_title {
          font-size: 50px;
          font-weight: 600;
          color: #586477;
          margin-bottom: 67px;
          width: 593px;
          text-wrap: wrap;
        }

        .mainitem {
          color: #666;
          font-size: 22px;
          font-weight: 400;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          width: 593px;
          text-wrap: wrap;

          .ricon {
            width: 50px;
            height: 47px;
            margin-right: 14px;
          }

          .label {
            color: #111;
            font-weight: 500;
          }
        }

        .play {
          display: flex;
          justify-content: flex-end;

          .goplay {
            text-align: center;
            font-size: 14px;
            color: #404856;
            font-weight: 400;

            img {
              width: 40px;
              height: 40px;
              margin: 8px;
            }
          }

          .goplay+.goplay {
            margin-left: 25px;
          }
        }
      }
    }

    .more_box {
      display: flex;
      justify-content: flex-end;
      margin-top: 80px;

      .more {
        vertical-align: middle;
        padding: 12px 0;
        border-bottom: 1px solid #000;

        span {
          color: #000;
          font-weight: 600;
          font-size: 20px;
        }

        img {
          width: 24px;
          height: 24px;
          margin-left: 20px;
        }
      }
    }

    // .menutop {
    //   width: 100%;
    //   display: flex;
    //   padding: 20px;

    //   .menuitem {
    //     width: 33.3%;
    //     text-align: center;
    //     font-size: 18px;
    //     font-weight: bold;
    //     cursor: pointer;

    //     .checked {
    //       border-bottom: 2px solid black;
    //       padding-bottom: 12px;
    //     }
    //   }
    // }

    // .content {
    //   padding: 40px;
    //   position: relative;

    //   .play {
    //     position: absolute;
    //     right: 20px;
    //     top: 20px;
    //     display: flex;

    //     .goplay {
    //       margin-right: 20px;

    //       img {
    //         // 图片居中
    //         display: block;
    //         margin: 0 auto;
    //         margin-bottom: 10px;
    //         width: 40px;
    //       }

    //       div {
    //         font-size: 14px;
    //         font-weight: normal;
    //         width: 90px;
    //       }
    //     }

    //     // .goplay 鼠标悬停时颜色变为蓝色
    //     .goplay:hover {
    //       color: #578aef;
    //       img {
    //         content: url(https://resources.wukongbc.com/icon-pc/play2.png);
    //       }
    //     }
    //   }

    //   .stitle {
    //     text-align: left;
    //     font-weight: bold;
    //     margin-bottom: 20px;
    //     padding-left: 20px;

    //     span {
    //       font-size: 24px;
    //     }
    //   }

    //   .c_main {
    //     display: flex;
    //     // 里面元素底部对齐
    //     align-items: flex-end;

    //     .left {
    //       width: 50%;
    //       padding: 20px;

    //       img {
    //         // height: 480px;
    //         width: 100%;
    //         // overflow: hidden;
    //       }
    //     }

    //     .right {
    //       width: 50%;
    //       padding: 20px;

    //       .r_main {
    //         text-align: left;

    //         .mainitem {
    //           margin: 6px 0;

    //           .ricon {
    //             width: 20px;
    //           }
    //         }
    //       }

    //       .imgright {
    //         width: 100%;
    //       }
    //     }
    //   }
    // }
  }
}
</style>
